.page-header {
  .inverted & {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    background: none !important;
    z-index: 1;
  }
  &__inner {
    max-width: rem-calc(1400) !important;
  }
}
