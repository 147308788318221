.pageintro {
  //@include uk-space(padding, left, big);
  //@include uk-space(padding, right, big);
  @extend .grid-x;
  @extend .grid-margin-x;
  justify-content: space-between;
  align-items: center;

  &__content {
    @extend .cell;
    @extend .medium-7;
  }

  &__image {
    display: none;
    @extend .cell;
    @extend .medium-4;
    @include breakpoint(medium) {
      display: initial;
    }
  }
}
