.logo {
  transition: opacity 0.2s ease-in-out;

  svg {
    width: 200px;

    @include breakpoint(medium) {
      width: 250px;
    }

    .inverted & {
      filter: drop-shadow(0 0 3px rgba(#000, 0.3));

      path {
        fill: $inverted-font-color !important;
      }
    }
  }

  &--small {
    font-size: 0;

    svg {
      width: rem-calc(50);
      height: auto;

      .st2 {
        fill: $white;
      }

      .st1 {
        stroke: $white;
      }
    }
  }
}

a.logo {
  &:hover,
  &:active,
  &:focus {
    opacity: 0.7;
  }
}
