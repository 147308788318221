.link--more {
  color: $button-background;
  text-decoration: underline;

  &::before {
    content: $link-arrow;
    margin-right: 0.5em;
    text-decoration: none;
    display: inline-block;
  }

  &:hover,
  &:active,
  &:focus {
    color: $button-background-hover;
  }
}
