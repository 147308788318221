$menu-teaser-background-colors: (
  $turquoise-color,
  $secondary-color,
  $tertiary-color,
);

.menu-teaser {
  @include xy-grid();

  margin: 0;

  @include breakpoint-down($grid-mobile-breakpoint) {
    .menu-teaser__box {
      margin: 0;

      &-content {
        @include uk-space(padding, left, big);
        @include uk-space(padding, right, big);
      }
    }
  }

  @include breakpoint($grid-mobile-breakpoint) {
    display: flex;
    margin: 0;

    > .menu-teaser__box {
      margin: 0;
      width: auto;

      &-content {
        text-align: center;
      }
    }

    &.menu-teaser--1-col > .menu-teaser__box {
      flex-basis: 100%;
    }
    &.menu-teaser--2-cols > .menu-teaser__box {
      flex-basis: calc(100% / 2);
    }
    &.menu-teaser--3-cols > .menu-teaser__box {
      flex-basis: calc(100% / 3);
    }
  }

  &__box {
    @include xy-cell($gutters: ());

    position: relative;
    list-style: none;
    display: flex;

    @for $n from 1 through length($menu-teaser-background-colors) {
      $color: nth($menu-teaser-background-colors, $n);
      &:nth-child(#{length($menu-teaser-background-colors)}n + #{$n}) {
        .menu-teaser__box-content {
          background: $color;
        }

        .menu-teaser__box-link {
          &:hover,
          &:active,
          &:focus {
            + .menu-teaser__box-content {
              background: scale-color($color, $lightness: -15%);
            }
          }
        }
      }
    }

    &-link {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      font-size: 0;
    }

    &-content {
      @extend %p-t-medium;
      @extend %p-r-medium;
      @extend %p-b-medium;
      @extend %p-l-medium;

      flex: 1;
      transition: background-color .3s;
    }

    &-image {
      @extend %m-b-#{$menu-teaser-image-margin-bottom} !optional;

      display: block;

      .menu-teaser__box-content.highlight & {
        @include uk-space(margin, top, $highlight-padding, $negative: true);
        @include uk-space(margin, right, $highlight-padding, $negative: true);
        @include uk-space(margin, left, $highlight-padding, $negative: true);
      }
    }

    &-description {
      display: block;
    }

    &-header {
      margin-bottom: 0;

      &,
      a {
        color: $white;
        font-weight: $font-weight-sans-serif-normal;
        text-decoration: none;
      }

      &::before {
        content: $link-arrow;
        margin-right: 0.5em;
      }
    }
  }
}
