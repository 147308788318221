$appVersion: '3.0.2';
$appLicense: 'MIT';
$_showWarnings: true;/*!
 * This CSS file is built by undkonsorten frontend-library [www.undkonsorten.com].
 *
 * Package: @undkonsorten/frontend-library
 * #{if(global_variable_exists(appVersion), "Version: #{$appVersion}", "")}
 * #{if(global_variable_exists(appLicense), "License: #{$appLicense}", "")}
 *
 * Made with <3 in Berlin.
 */
$site-name: "default";

// Helpers
@import "helpers/all";

// Default project settings (Foundation)
@import "settings";

// Libraries and project overrides
@import "includes/all";
