@mixin factbox($background-color: $light-gray) {
  padding: $global-padding;
  background-color: $background-color ;
  text-transform: uppercase;
  color: $primary-color;
  display: inline-block;
  font-family: $font-family-serif;
  font-size: 1em;
  line-height: 2em;
}

.text--factbox {
  @include factbox(lighten($light-gray,6.5%));
}
