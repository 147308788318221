.nav--breadcrumbs {
  @include uk-space(padding, left, big);
  @include uk-space(padding, right, big);
  max-width: $menu-breadcrumbs-max-width;
  margin: {
    left: auto;
    right: auto;
  }
  font-family: $font-family-serif;
  text-transform: uppercase;
  a {
    text-decoration: none;
  }
}


.menu {

  // MAIN MENU
  // ---------
  .nav--main & {
    .menu__item {
      > .menu__link {
        overflow: hidden;
        white-space: nowrap;
        padding: 0 0.25em;
        margin: 0.5rem 0.75rem;
        position: relative;
        line-height: 1.25em;
        outline: none;
        color: $primary-color;
        text-transform: uppercase;
        font-weight: $global-weight-normal;

        body:not(.homepage):not(.inverted) & {
          color: $dark-gray;
        }

        body.inverted:not(.homepage) & {
          color: darken($white, 20%);
        }

        .menu__link-text {
          position: relative;
          display: inline-block;
          transition: transform 0.3s;

          &::before {
            position: absolute;
            top: 150%;
            left: 50%;
            color: $primary-color;
            transform: translateX(-50%);
            content: attr(data-hover);
            font-weight: $global-weight-bold;

            .inverted & {
              color: $white;
            }
          }
        }

        &:hover,
        &:active,
        &:focus {
          .menu__link-text {
            transform: translateY(-150%);
          }
        }

        .inverted & {
          color: $white;
          text-shadow: 0 0 3px rgba(#000, 0.3);
        }
      }

      &--cur,
      &--act {
        > .menu__link {
          color: $primary-color !important;
          font-weight: $global-weight-bold;

          .inverted & {
            color: $white !important;
          }
        }
      }
    }
  }


  // FOOTER MENU
  // -----------
  .nav--footer > & {
    display: flex;
    flex-flow: row nowrap;

    &.menu__level-1 {
      > .menu__item {
        display: none;
        &:nth-child(1) {
          display: initial;
        }
        @include breakpoint(medium) {
          display: initial;
        }
      }
    }
    .menu__item {
      text-align: left;

      &--cur,
      &--act {
        .menu__link {
          font-weight: $global-weight-normal;
        }
      }
    }

    .menu__link {
      color: $white;
      text-transform: uppercase;
      text-decoration: none;
    }

    > .menu__item {
      @include breakpoint(medium) {
        @include uk-space(margin, left);
      }

      @include breakpoint-down($grid-mobile-breakpoint) {
        &:first-child {
          margin-left: 0;
        }
      }

      //noinspection SassScssUnresolvedPlaceholderSelector
      > .menu__link {
        @extend %p-b-small;
        @extend %m-b-small;

        font-size: rem-calc(16);
        font-family: $font-family-serif;
        border-bottom: 1px solid $dark-gray;
      }
    }

    .menu__level-2 {
      .menu__item {
        padding-bottom: 0;
      }

      .menu__link {
        font-size: rem-calc(12);
        line-height: 2;
        display: inline-block;

        &:hover,
        &:active,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }


  // MOBILE MENU
  // -----------
  .nav--mobile & {
    .menu__link {
      padding: 1em 0;
      font-size: rem-calc(22);
      color: $white;
      text-align: center;
    }
  }
}

@keyframes menu-hover {
  100% {
    width: 100%;
  }
}
