.textmedia {
  &--media-intext-nowrap {
    .box--expanded & {
      max-width: initial;
    }
    @include breakpoint('medium') {
      @include xy-grid();
      .textmedia__media-wrap {
        margin-bottom: 0 !important;
        .textmedia__media {
          position:relative;
          top:50%;
          transform: translateY(-50%);
        }
      }
      &.textmedia--media-right {
        .textmedia__media-wrap {
          @include flex-order(2);
        }
        .textmedia__text-wrap {
          @include flex-order(1);
        }
      }
    }
  }

  ul > li {
    position: relative;
    padding-left: 1.75em;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.45em;
      font-size: 0.9em;
      background-color: $medium-gray;
      width: 0.9em;
      height: 0.9em;
    }
  }

  @include breakpoint($grid-mobile-breakpoint) {
    .box--has-subheader & {
      .textmedia__text-wrap {
        margin-top: rem-calc(16);
      }
    }
  }
}
