.text {
  @include clearfix;
  ul > li {
    position: relative;
    padding-left: 1.75em;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.45em;
      font-size: 0.9em;
      background-color: $medium-gray;
      width: 0.9em;
      height: 0.9em;
    }
  }
  @include breakpoint($grid-mobile-breakpoint) {
    .box--has-subheader & {
      .textmedia__text-wrap {
        margin-top: rem-calc(16);
      }
    }
  }
}
