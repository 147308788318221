.textgrafic {
  @extend .grid-x;
  @extend .grid-margin-x;
  justify-content: space-between;
  align-items: center;

  &__content {
    order: 2;
    @include breakpoint(large) {
      @include xy-cell(6);
    }
    @include breakpoint(medium) {
      @include xy-cell(7);
    }
  }

  &__image {
    @include xy-cell(10);
    margin-left: auto;
    margin-right: auto;
    @include breakpoint(medium down) {
      @include uk-space(margin,bottom,big);
    }
    @include breakpoint(large) {
      @include xy-cell(5);
    }
    @include breakpoint(medium) {
      @include xy-cell(4);
    }
    order: 1;
    .textgrafic--image-right & {
      @include breakpoint(medium) {
        order: 3;
      }
    }

    img {
      width: 100%;
    }
  }
}
