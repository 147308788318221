.off-canvas {
  @extend %p-t-default;
  @extend %p-b-default;

  &__toggle {
    @extend %m-l-default;

    &--hamburger {
      @include hamburger($primary-color, $dark-gray, 25px, 20px, 3px);

      .inverted & {
        @include hamburger($white, $light-gray, 25px, 20px, 3px);
      }
    }
  }

  &__header {
    &-close {
      text-align: center;
      position: absolute;
      top: 0;
      right: 0;
      background: $tertiary-color;
      width: $off-canvas-close-size;
      height: $off-canvas-close-size;
      clip-path: polygon(0 0, 100% 0, 100% 100%);

      &-button {
        color: $white;
        position: absolute;
        top: 0;
        right: 0;
        width: $off-canvas-close-button-width;
        height: $off-canvas-close-button-height;

        &:hover,
        &:active,
        &:focus {
          color: $light-gray;
        }
      }
    }
  }

  &__logo {
    @extend %p-t-default;
    @extend %p-b-default;
    @extend %p-l-default;
    @extend %p-r-default;

    text-align: center;

    .logo--small {
      display: inline-block;
    }
  }
}
