.textheader {
  //@include uk-space(padding, left, big);
  //@include uk-space(padding, right, big);
  @extend .grid-x;
  @extend .grid-margin-x;
  align-items: flex-start;

  &__header {
    @extend .cell;
    @extend .large-4;
  }


  &__text {
    @extend .cell;
    @extend .large-8;

    align-items: flex-start;
  }

}
