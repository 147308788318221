.list--references {
  .list__item {
    @include uk-space(margin, bottom, big);

    &:last-child {
      margin-bottom: 0;
    }

    &-header, &-header a {
      color: $primary-color;

      .subheader {
        @include uk-space(margin, bottom, tiny);
        color: $dark-gray;
        font-size: rem-calc(16);
      }
    }
    &-header a {
      text-decoration: none;
    }

    &-meta {
      @include uk-space(margin, top, small);

      .meta__items:not(:first-child)::before {
        margin: {
          left: 0.5em;
          right: 0.5em;
        }
      }

      .meta__item:not(:first-child)::before {
        margin: {
          left: 0.5em;
        }
      }
    }

    .link--more,
    .button {
      @include uk-space(margin, top, small);

      display: inline-block;
    }

    .button {
      margin-bottom: 0;
    }

    @include breakpoint-down($grid-mobile-breakpoint) {
      margin-bottom: 4rem;

      &-media {
        @include uk-space(margin, left, big, $negative: true);
        @include uk-space(margin, right, big, $negative: true);
        @include uk-space(margin, top, big, $negative: true);

        position: relative;

        .media__image {
          img {
            width: 100%;
          }
        }
      }

      &-content {
        @include uk-space(margin, left, $negative: true);
        @include uk-space(margin, right, $negative: true);
        @include uk-space(margin, bottom, $negative: true);
        @include uk-space(padding, left);
        @include uk-space(padding, right);
        @include uk-space(padding, top);
        @include uk-space(padding, bottom);

        margin-top: -3rem;
        background: $white;
        position: relative;
      }
    }

    @include breakpoint($grid-mobile-breakpoint) {
      display: flex;
      max-width: 100%;

      &-media,
      &-content {
        flex-basis: 50%;
      }

      &:nth-child(odd) {
        .list__item-media {
          @include uk-space(padding, right, small);
        }

        .list__item-content {
          @include uk-space(padding, left, small);
        }
      }

      &:nth-child(even) {
        flex-direction: row-reverse;

        .list__item-media {
          @include uk-space(padding, left, small);
        }

        .list__item-content {
          @include uk-space(padding, right, small);
        }
      }
    }
  }

  .list__show-all-link {
    margin-bottom: 0;

    &-wrap {
      @extend %m-t-default;

      @include breakpoint($grid-mobile-breakpoint) {
        text-align: center;
      }
    }
  }

  &.list--short {
    .list__item {
      @include breakpoint($grid-mobile-breakpoint) {
        align-items: center;
      }
    }
  }
}
