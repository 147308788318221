.layout-page {
  max-width: $page-width;
  //position: relative;
  margin: {
    left: auto;
    right: auto;
  }

  body.inverted & {
    background: $white;
  }
  background: $white url(../img/backgrounds/page__background-triangle.svg) no-repeat right -20vw;
  @include breakpoint(xxlarge) {
    background-position: right -275px;
  }
  background-size: 50% auto;
}

body:not(.inverted) {
  .col-0 {
    @include uk-space(padding, top, big);
  }
}
html {
  scroll-behavior: smooth;
}
ol {
  list-style-position: inside;
}
