@mixin box-striped-background(
  $base-background,
  $header-color,
  $subheader-color: $header-sub-color,
  $background-diff-mode: darken,
  $text-color: $body-font-color,
  $button-background: $button-background,
  $button-background-hover: $button-background-hover,
  $button-color: $button-color
) {
  @include uk-space(padding, top, big);
  @include uk-space(padding, bottom, big);

  position: relative;

  .box__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
    }

    &::before {
      left: 0;
      background: if($background-diff-mode == darken, darken($base-background, 3%), $base-background);
      z-index: 1;
    }

    &::after {
      background: if($background-diff-mode == darken, darken($base-background, 6%), lighten($base-background, 6%));
      z-index: 2;
      left: 0;
      clip-path: polygon(100% 10%, 100% 100%, 0 100%, 0 50%);

      @include breakpoint(medium) {
        left: 30%;
        clip-path: polygon(75% 0, 100% 0, 100% 100%, 0 100%);
      }

      @include breakpoint($grid-mobile-breakpoint) {
        left: 45%;
      }
    }
  }

  .box__container {
    position: relative;
    z-index: 3;
  }

  #{$header-levels} {
    color: $header-color;

    .subheader {
      color: $subheader-color;
    }
  }

  p {
    color: $text-color;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .button {
    background: $button-background;
    color: $button-color;
    margin-bottom: 0;

    &:hover,
    &:active,
    &:focus {
      background: $button-background-hover;
    }
  }

  @include breakpoint($grid-mobile-breakpoint) {
    .textmedia {
      max-width: rem-calc(1000);
      display: flex;

      header,
      &__text-wrap {
        flex-basis: 50%;
      }

      header {
        @include uk-space(padding, right, medium);

        .subheader {
          @include uk-space(margin, bottom);

          font-size: rem-calc(16);
        }
      }
    }
  }
}

.col-0 {
  .box--gray {
    @include box-striped-background(
      $base-background: $white,
      $header-color: $primary-color
    );
  }

  .box--tertiary {
    @include box-striped-background(
      $base-background: $tertiary-color,
      $header-color: $white,
      $subheader-color: $light-gray,
      $background-diff-mode: lighten,
      $text-color: $white,
      $button-background: $white,
      $button-background-hover: scale-color($white, $lightness: -5%),
      $button-color: $tertiary-color
    );

    .box__background {
      &::after {
        clip-path: polygon(75% 0, 100% 0, 100% 100%, 50% 100%);
      }
    }

    @include breakpoint-down(medium) {
      .button {
        display: table;
      }
    }

    @include breakpoint($grid-mobile-breakpoint) {
      .textmedia {
        align-items: center;

        .headline {
          margin-bottom: 0;
        }

        header {
          flex-basis: 30%;
        }

        &__text-wrap {
          flex-basis: 70%;
          margin-top: 0;
          text-align: right;
        }
      }
    }
  }

  .box--primary {
    @include box-striped-background(
      $base-background: $primary-color,
      $header-color: $white,
      $subheader-color: $light-gray,
      $text-color: $white,
      $button-background: $white,
      $button-background-hover: $light-gray,
      $button-color: $primary-color
    );

    .box__background {
      &::before,
      &::after {
        background: $primary-color;
      }
    }
  }
}
