#{$header-levels},
#{$header-level-classes} {
  font-family: $font-family-serif;
}

#{$header-levels},
#{$header-level-classes} {
  .subheader {
    @extend %m-b-small;

    text-transform: uppercase;
  }
}
