.textimage {

  //@include uk-space(padding, left, big);
  //@include uk-space(padding, right, big);
  @extend .grid-x;
  @extend .grid-margin-x;
  align-items: center;

  &__content {
    @extend .cell;
    @extend .medium-6;
    order: 2;
  }

  &__image {
    order: 1;
    .textimage--image-right & {
      @include breakpoint(medium) {
        order: 3;
      }
    }
    @include breakpoint(medium down) {
      @include uk-space(margin,bottom,big);
    }
    @extend .cell;
    @extend .medium-6;

    align-items: flex-start;
  }

}
