form {
  label {
    color: $primary-color;
    font-family: $font-family-serif;
    margin-bottom: $global-padding;
  }
  .form-group {
    margin-bottom: $global-padding * 2;
    input, textarea {
      border: 2px solid rgba($primary-color, 0.5);
      background-color: rgba($light-gray, .3 );
    }
    input:focus, textarea:focus {
      border: 2px solid rgba($primary-color, 1);
      background-color: rgba($light-gray, .1 );
    }
  }
  .form-navigation {
    text-align: right;
  }
}

