h1,h2,h3,h4,h5,h6 {
  strong {
    font-weight: inherit;
  }
}
:not(pre) > code[class*="language-"] {
  padding-left: .3em;
  padding-right: .3em;
  margin-right: .2em;
}
.code-toolbar {
  margin-bottom: $global-padding * 1.5;
  & > .code-toolbar {
    margin-bottom: 0;
  }
}
