@include icon-add(up, $value: "\f176");
@include icon-add(left, $value: "\f177");
@include icon-add(right, $value: "\f178");

.postlist {
  &__post {
    margin-top: $global-padding * 3;
    cursor: pointer;
    padding: $global-padding 0;
    img {
      transition: transform 0.3s ease;
    }
    .post-image {
      overflow: hidden;
    }
    &:hover {
      img {
        transform: scale(1.02);
      }
      a {
        color: $anchor-color-hover;
      }
    }
    .list {
      &__item {
        &-header {
          margin-bottom: $global-padding / 4;
          @include breakpoint-down('large') {
            margin-top: $global-padding;
          }
        }
      }
    }
    .postmetagroup {
      color: $dark-gray;
      margin-left: 0;
      margin-bottom: $global-padding;
      &__item {
        padding: 0;
        &:after {
          content: '|';
          padding: 0 $global-padding / 2;
        }
        &:last-child:after {
          display: none;
        }
        &--authors {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .postmetagroup__listitem {
            &:after {
              content: ',';
              margin-right: 3px;
            }
            &:last-child:after {
              display: none;
            }
          }
        }
      }
      &__body {
        margin-left: 0;
      }
    }
    .read-more-link {
      text-underline-offset: 5px;
      &:focus {
        color: $anchor-color-hover;
      }
    }
  }
}

.postheader {
  &__media {
    margin-top: $global-padding * 1.5;
  }
  &__meta {
    color: $dark-gray;
    &--date {
      &:after {
        content: '/';
        padding: 0 $global-padding / 2;
      }
    }
    .postmetagroup {
      &__item {
        display: inline-block;
        padding: 0;
      }
    }
  }
}
body:not(.inverted) {
  & .col-0 {
    & +.col-0 {
      padding-top: 0;
    }
  }
}
#c-blog-header.box {
  padding-bottom: $global-padding*2;
}

.textmedia {
  &__text-wrap {
    & img[class*="size-full"] {
      display: block;
      margin-bottom: $global-padding;
    }
  }
}
.blog-post {
  hr {
    margin: $global-padding * 3 auto;
  }
  .textmedia {
    & code, a {
      word-break: break-word;
    }
  }
  .postauthor {
    &__body {
      display: grid;
      column-gap: $global-padding;
      justify-content: start;
    }
    &__intro {
      grid-column: 1/4 ;
      font-size: $global-font-size * 1.25;
      opacity: initial;
      margin-bottom: $global-padding;
      h3 {
        margin: 0;
      }
    }
    &__name {
      grid-column: 2/3;
      font-size: $global-font-size * 1.25;
    }
    &__avatar {
      grid-column: 1/1;
      grid-row: 2/4;
    }
    &__subline {
      grid-column: 2/4;
      font-size: 1.1em;
    }
    &__social {
      display: initial;
      margin-top: 0;
      grid-row: 4/4;
      grid-column: 2/4;
      @include breakpoint(phone) {
        grid-row: 2/3;
        grid-column: 3/4;
      }
    }
  }
  #c-blog-authors {
    margin-bottom: $global-padding*2;
  }
  .postmetagroup {
    &__item {
      &--tags {
        .postmetagroup__list {
          li {
            color: $primary-color;
            display: inline-block;
            padding: $global-padding/4 $global-padding;
            background-color: #efefd9;
            border-radius: $global-padding;
            margin-right: $global-padding;
            margin-bottom: $global-padding;
            &:after {
              display: none;
            }
            a {
              color: inherit;
              &:hover {
                color: $anchor-color-hover;
              }
            }
          }
        }
      }
    }
  }
  .postcommentsform, .postcomments {
    margin-top: $global-padding * 2;
    @include breakpoint-down(medium) {
      margin-top: $global-padding * 3;

    }
    &__content {
      .alert-info {
        //noinspection SassScssUnresolvedVariable
        background-color: lighten($success-color,35%);
        padding: $global-padding;
        margin-bottom: $global-padding;
      }
    }
  }
}
.postcomments__list {
  .postcomment {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    justify-content: start;
    grid-template-areas:
      'avatar avatar meta meta meta meta'
      'body body body body body body';
    column-gap: $global-padding;
    @include breakpoint(phone) {
      grid-template-areas:
      'avatar meta meta meta meta meta'
      'body body body body body body';
      column-gap: $global-padding * 1.5;
    }
    @include breakpoint(medium) {
      grid-template-areas:
      'avatar meta meta meta meta meta'
      '. body body body body body';
      column-gap: $global-padding * 2;
    }
    &__avatar {
      grid-area: avatar;
      margin-right: 0;
    }
    &__meta {
      grid-area: meta;
    }
    &__body {
      grid-area: body;
    }
    &__comment {
      word-break: break-word;
    }
    &__author {
      margin-top: 0;
      @include breakpoint(medium) {
        margin-top: .5rem;
      }
    }
  }
}
.box {
  &__multiple-buttons {
    display: grid;
    gap: $global-padding/2;
    align-content: end;
    grid-template-columns: auto;
    grid-template-areas:
        'comment'
        'all';
    @include breakpoint(medium) {
      gap: $global-padding*2;
      grid-template-columns: auto auto auto;
      grid-template-areas:'comment all .';
    }
    @include breakpoint(large) {
      grid-template-columns: auto auto auto auto;
      grid-template-areas:'comment all . .';
    }
    .button {
      &__comment {
        grid-area: comment;
      }
      &__all-blog-posts {
        grid-area: all;
      }
    }
  }
}
// @todo Remove if all posts are refactored and post images (first image in textmedia) are not in rte field
.blog-post #c-blog-header + .box--textmedia > .box__container > .textmedia > .textmedia__text-wrap p:first-child img {
  display: none;
}
// @todo Remove if all posts are refactored and post images (first image in textmedia) are not in rte field
.blog-post #c-blog-header + .box--textmedia > .box__container > .textmedia > .textmedia__text-wrap p img {
  display: block;
  margin-bottom: $global-padding;
}
$pagination-item-width: 3em;
.blogpagination {
  &__list {
    display: flex;
    gap: 3%;
    flex-wrap: wrap;
    @include breakpoint(large) {
      justify-content: center;
    }
  }
  &__link {
    background-color: $light-gray;
    color: $primary-color;
    text-decoration: none;
    border-width: 0;
    border-radius: 50%;
    font-size: 1em;
    padding-top: $pagination-item-width * 0.25;
    width: $pagination-item-width * 0.83;
    height: $pagination-item-width * 0.83;
    @include breakpoint(phone) {
      padding-top: 1em;
      width: $pagination-item-width;
      height: $pagination-item-width;
    }
    text-align: center;
    font-weight: 600;
    &:hover {
      background-color: lighten($secondary-color,35%);
      color: $primary-color;
    }
  }
  &__item {
    margin-top: $global-padding / 2;
    &--current {
      background-color: $primary-color;
      color: $white;
    }
  }
}
