.image-teaser-box {
  position: relative;

  &__image {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(to top, transparent, rgba(#000, 0.3) 90%);
    }
  }

  &__text {
    header {
      margin-bottom: 3rem;
    }

    #{$header-levels} {
      background: $primary-color;
      color: $white;
      margin-bottom: 0;
      font-weight: $font-weight-serif-normal;
    }

    &-content p {
      line-height: 1.7;

      &:last-child,
      .button {
        margin-bottom: 0;
      }
    }
  }

  @include breakpoint(small only) {
    overflow: hidden;

    &__image {
      height: 100%;

      * {
        height: inherit;
      }

      img {
        object-fit: cover;
      }
    }

    &__text {
      @include uk-space(padding, bottom);
    }
  }

  @include breakpoint(medium) {
    height: 0;
  }

  @include breakpoint(medium only) {
    padding-bottom: 100%;
  }

  @include breakpoint-down(large) {
    &__text {
      padding-top: 150px;
    }
  }

  @include breakpoint(large only) {
    padding-bottom: 75%;

    &__text {
      padding-top: 175px;
    }
  }

  @include breakpoint(large) {
    &__image {
      clip-path: polygon(100% 0, 100% 15%, calc(100% / 3) 100%, 0 100%, 0 0);
    }
  }

  @include breakpoint-down(xlarge) {
    margin: 0;

    &__image {
      position: absolute;
    }

    &__text {
      @include uk-space(padding, left);
      @include uk-space(padding, right);

      margin: auto;
      position: relative;

      header {
        padding: {
          left: 1.25rem;
          right: 1.25rem;
        }
      }

      #{$header-levels} {
        padding: 0.75rem 0 0.5rem;
        display: inline;
        box-shadow: 1rem 0 0 $primary-color, -1rem 0 0 $primary-color;
        box-decoration-break: clone;
        line-height: 1.4;
      }

      header,
      &-content {
        max-width: rem-calc(600);
        margin: {
          left: auto;
          right: auto;
        }
      }

      &-content {
        @include uk-space(padding, top);
        @include uk-space(padding, right);
        @include uk-space(padding, bottom);
        @include uk-space(padding, left);

        background: $white;
      }
    }
  }

  @include breakpoint-down(medium) {
    &__text-content p {
      font-size: 1rem;
    }
  }

  @include breakpoint(medium) {
    &__text-content p {
      font-size: 1.2rem;
    }
  }

  @include breakpoint(xlarge) {
    height: auto;

    &__image {
      position: initial;
    }

    &__text {
      position: absolute;
      top: 55%;
      right: 6rem;
      left: 0;

      header {
        text-align: right;
      }

      #{$header-levels} {
        padding: 0.75rem 1.25rem 0.5rem;
        display: inline-block;
      }

      &-content {
        margin-left: 65%;
      }
    }
  }
}
