.page-footer {
  max-width: $page-width;
  color: $white;

  &__content {
    .logo--small {
      //noinspection SassScssUnresolvedPlaceholderSelector
      @extend %m-b-small;
    }

    p {
      text-transform: uppercase;
      font-size: rem-calc(12);
      line-height: 2;
    }

    @include breakpoint-down($grid-mobile-breakpoint) {
      text-align: center;
    }

    @include breakpoint(medium only) {
      @include uk-space(margin, bottom, medium);
    }
  }

  @include breakpoint-down($grid-desktop-breakpoint) {
    @include uk-space(padding, left);
    @include uk-space(padding, right);
  }

  @include breakpoint($grid-mobile-breakpoint) {
    &__wrapper {
      display: flex;
    }

    &__content {
      flex: 0 1 auto;
      min-width: 20%;
    }

    &__menu {
      flex: 1 1 auto;
    }
  }
}
