// Page hero
.col-1 {
  > .box {
    margin-bottom: 0 !important;
  }
}

// Page
.col-0 {
  > .box {
    @include uk-space(padding, left, big);
    @include uk-space(padding, right, big);

    max-width: $box-max-width;
    .layout--narrow & {
      max-width: $box-max-width-narrow;
    }
    margin: {
      left: auto;
      right: auto;
    }

    &.box--expanded,
    &.box--menu-pages,
    &.box--menu-subpages,
    &.box--references-list {
      max-width: 100%;
    }

    &.box--menu-pages,
    &.box--menu-subpages,
    &.box--html {
      padding: {
        left: 0;
        right: 0;
      }
    }

    &:last-child:not(.box--menu-pages):not(.box--menu-subpages):not(.box--striped-background):not(.box--html) {
      @include uk-space(padding, bottom, big);
    }
  }
}
