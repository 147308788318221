.top-bar {
  @include breakpoint($grid-desktop-breakpoint) {
    @include uk-space(padding, top);
    @include uk-space(padding, bottom);
  }

  @include breakpoint-down($grid-desktop-breakpoint) {
    @include uk-space(padding, top);
    @include uk-space(padding, right);
    @include uk-space(padding, bottom);
    @include uk-space(padding, left);
  }

  .inverted & {
    &,
    ul {
      background: none !important;
    }
  }
}
